<template>
    <section :class="quickCartClass">
        <div class="cart_data_wrapped">

            <div class="shopping_bag_head">

                <h2 class="font-active mb-0">
                    <span class="icon">
                        <SvgHeaderSvg :type="'cart'" />
                    </span>
                    My Shopping Bag
                    <span class="cart_count">({{ cartCount }})</span>
                </h2>

                <span @click="closeCart">
                    <SvgHeaderSvg type="close" class="largesvg" />
                </span>

            </div>

            <div class="avail_check" v-if="!skeleton && cartData && cartData.length > 0">
                <div class="avail_status mt-2 ps-2" v-if="cartTotal?.availability === 0">
                    <p class="mb-0">Unfortunately, one or more items in your cart are no longer available.</p>
                </div>

                <div class="avail_status mt-2 ps-2" v-if="cartTotal?.is_duplicate === 1">
                    <p class="mb-0">Unfortunately, you have the same unique diamond attached to multiple items in your
                        cart.</p>
                </div>
            </div>

            <div class="loader" v-if="skeleton">

                <img width="200" loading="lazy" height="200" alt="loader" src="~/assets/assets/loader.svg" />

            </div>

            <div v-else class="produ_block">

                <template class="new_block" v-for="item in cartData" :key="item.id">

                    <div class="product_block" v-if="!['gift_card'].includes(item.type)">

                        <CartQuickJewelry v-if="item.type === 'shop'" :key="item.id" :item="item"
                            @update:cartVisible="closeCart" @removeFromCart="removeFromCart"
                            @removeAppraisal="removeAppraisal" @addAppraisal="addAppraisal" />

                        <CartQuickDiamond v-if="item.type === 'loose'" :key="item.id" :item="item"
                            @update:cartVisible="closeCart" @removeFromCart="removeFromCart"
                            @removeAppraisal="removeAppraisal" @addAppraisal="addAppraisal" />

                        <CartQuickPreset v-if="['preset'].includes(item.type)" :key="item.id" :item="item"
                            @update:cartVisible="closeCart" @removeFromCart="removeFromCart"
                            @removeAppraisal="removeAppraisal" @addAppraisal="addAppraisal" />

                        <CartQuickCyor v-if="['cyor', 'cyop', 'cyoe'].includes(item.type)" :key="item.id"
                            @update:cartVisible="closeCart" :item="item" @removeFromCart="removeFromCart"
                            @removeAppraisal="removeAppraisal" @addAppraisal="addAppraisal" />


                    </div>

                </template>

                <div class="new_block">
                    <CommonCleaningKit class="product_block" v-if="!$country.is_india && cartData && cartData.length > 0" :cleaningKit="cleaningKit"
                        @removeFromCart="removeFromCart" :page="'quick_cart'" :item="giftCards[0]"
                        @addCleaningKit="getCartData" @updateCleaning="fetchCleaningKit" />

                    <CartQuickFreeProduct v-if="$country.free_product && freeData.has_free_product"
                        :item="freeData.free_product" />

                </div>

                <div class="new_block" v-if="cartData && cartData.length >= 1">

                </div>
            </div>
        </div>

        <div class="my-5 py-5" v-if="cartData && cartData.length < 1" key="cartSkeleton">
            <CommonEmptyState :page="'cart'" />
        </div>


        <div class="sub_total_cart" v-if="!skeleton && cartData && cartData.length > 0">
            <div class="product_offer" v-if="!$country.is_india">

                <CartQuickFreeProductOffers @isOffersVisible="updateOffersVisibility" />
            </div>
            <div class="total_cart_block">
                <div class="sub_total font-active">

                    <p class="mb-0">Total</p>

                    <p class="mb-0">{{ $formatPrice(cartTotal.total) }}</p>

                </div>

                <p class="tax" v-if="!$country.is_india">(Exclusive of tax)</p>
                <p class="tax" v-if="$country.is_india">(Inclusive of tax)</p>

                <div class="view_check_button">

                    <div class="quick_buttons" @click="viewBag">

                        <span class="button-base view_bag">
                            View Bag
                        </span>

                    </div>

                    <div class="quick_buttons" @click="checkOut">

                        <span class="button-base checkout">
                            <SvgHeaderSvg :type="'mini_checkout'" class="checkicon" />
                            Checkout
                        </span>

                    </div>

                </div>
            </div>

        </div>

    </section>

</template>

<script setup>
import { cartStore } from '@/stores/cart/cart';
const props = defineProps(['cartVisible'])
const store = cartStore()
const localePath = useLocalePath();
const router = useRouter();
const { $formatPrice, $country } = useNuxtApp();
const isOffersVisible = ref(false);
const skeleton = ref(true);
const emit = defineEmits(['update:cartVisible', 'closeCart', 'isCouponVisible']);


const freeData = computed(() => {
    return store.getCartTotal()
})

const quickCartClass = computed(() =>
    isOffersVisible.value ? 'quick_cart expanded' : 'quick_cart'
);

const updateOffersVisibility = (visibility) => {
  isOffersVisible.value = visibility;
};

const cartData = computed(() => {
    return store.getCartListing()
})

const giftCards = computed(() => {
    return cartData.value.filter(item => item.type === 'gift_card')
})


const cartTotal = computed(() => {
    const item = store.getCartTotal()
    if (import.meta.client && item !== null && window.affirm !== undefined) {
        nextTick(() => {
            window.affirm.ui.ready(() => {
                window.affirm.ui.refresh();
            });
        });
    }
    return item
})

function getCartData() {
    store.setCartListing()
        .then((res) => {
            if (res?.success) {
                skeleton.value = false
            }
        }).catch((err) => {
            console.log(err, 'error')
        })
}

const closeCart = () => {
    emit('update:cartVisible', true);
};
const handleRouteChange = () => {
    emit("update:cartVisible", true);
};
async function removeFromCart(item) {
    await store.removeFromCart(item);
    if (item.type === 'gift_card') {
        fetchCleaningKit()
    }
}

async function addAppraisal(type, id) {
    await store.addAppraisal({ type, id })
    getCartData()
}

async function removeAppraisal(type, id) {
    await store.removeAppraisal({ type, id })
    getCartData()
}

function viewBag() {
    window.location.href = localePath({ path: '/cart' });
    store.setCartVisible(false);
}

const cartCount = computed(() => {
    return store.fetchCartCount()
})

function checkOut() {
    if (cartTotal.value.availability === 0) {
        console.log(cartTotal.value.availability, 'cartTotal.value.availability');

        alert("Unfortunately, One of your cart item is no longer available.")
        return
    }

    if (cartTotal.value.is_duplicate === 1) {
        alert("Unfortunately, You have the same unique diamond attached to multiple items in your cart.")
        return
    }
    store.setCartVisible(false);
    window.location.href = localePath({ path: '/checkout/address' });
    // router.push(localePath({ path: '/checkout/address' }));
}
const cleaningKit = ref([])

const fetchCleaningKit = async () => {
    // cleaningLoader.value = true;
    if (!$country?.is_india) {     
        try {
            const res = await $fetch(useCountryPath('/simple/get-cleaning-kit'), {
                method: 'GET',
            });
    
            if (res?.success) {
                cleaningKit.value = res.data;
            }
        }
        catch (error) {
            console.log('Error fetching cleaning kit:', error);
        }
        finally {
            // cleaningLoader.value = false;
        }
    }
};

onMounted(() => {
    fetchCleaningKit()
    getCartData();
    router.beforeEach((to, from, next) => {
        handleRouteChange();
        next();
    });

})


</script>

<style scoped>
/* section.quick_cart {
    overflow: auto;
    max-height: calc(100vh - 150px);
} */
section.quick_cart {
    overflow: auto;
    max-height: calc(100vh - 138px);
    scrollbar-width: none;
}

section.quick_cart.expanded {
    overflow: auto;
}

.produ_block {
    margin-bottom: 30%;
}

.shopping_bag_head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 10;
    border-top-left-radius: 8px;
    background: #ffffff;
    padding: 16px 12px;
    border-bottom: 1px solid #eaeaea;
}

.loader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 70vh;
}

.shopping_bag_head h2 {
    font-size: 20px;
    color: #212529;
}

.shopping_bag_head span {
    font-size: 16px;
    color: #606060;
}

.tax {
    color: #909090;
    font-size: 12px;
    margin-bottom: 12px;
}

.avail_status p {
    font-size: 12px;
    color: rgb(255, 0, 0);
    font-weight: 600;
}

.product_divided {
    background: #f5f5f5;
    padding: 12px 12px;
}

.product_divided h3 {
    font-size: 18px;
    font-weight: 400;
}

.icon {
    padding-right: 12px;
    padding-left: 12px;
}

.sub_total_cart {
    background: #ffffff;
    box-shadow: 0px -4px 8px 0px #0000001A;
    position: fixed;
    bottom: 0;
    border-bottom-left-radius: 8px;
    max-width: 400px;
    min-width: 400px;
    /* padding: 20px; */
    /* padding: 16px; */
}

.quick_coupon_block {
    transition: margin 0.3s ease;
    /* margin: 12px 16px; */
    margin: 16px;
}

.total_cart_block {
    margin: 16px;
}

.checkicon {
    margin-right: 10px;
}

svg.svg-head.checkicon {
    height: 17px;
    width: 20px;
}

.divider-wrapper {
    margin: 0 0px;
}

.divider {
    border: none;
    border-top: 1px solid #c4c4c4;
    margin: 5px 0;
}

.sub_total p {
    font-size: 18px;
    font-weight: 500;
}

.product_divided span {
    font-size: 12px;
    font-weight: 400;
    color: #606060;
}

.product_block {
    border-bottom: 1px solid #eaeaea;
    padding: 12px;
}

.sub_total {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4px;
}

.view_check_button {
    display: flex;
    gap: 12px;
    align-items: center;
}

.button-base {
    font-size: 14px !important;
    font-weight: 600;
    letter-spacing: 1.5px;
    text-align: center;
    line-height: 1;
    cursor: pointer;
    min-height: 45px;
    max-height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding: 14px 30px; */
    padding: 8px 14px;
}

.icon svg {
    height: 25px;
    transform: scale(1.1);
}

.largesvg {
    cursor: pointer;
    transform: scale(1.2);
    height: 23px;
}

.view_bag {
    background: #fff !important;
    border: 1.5px solid #212529;
    text-transform: uppercase;
    color: #212529;
    border-radius: 4px;
    min-width: 149px;
    max-width: 149px;
    font-size: 14px;
    letter-spacing: 1.5px;
    text-align: center;
}

.checkout {
    background: var(--green) !important;
    border: none;
    text-transform: uppercase;
    min-width: 200px;
    max-width: 200px;
    color: #ffff;
    border-radius: 4px;
}

::-webkit-scrollbar {
    width: 5px;
    height: 3px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f5f5f5;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #c4c4c4;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #c4c4c4;
}

@media (max-width : 767px) {

    /* section.quick_cart {
        overflow: auto;
        max-height: calc(100vh - 245px);
    } */
    .quick_cart {
        overflow: auto;
        max-height: calc(100vh - 178px);
    }

    .quick_cart.expanded {
        overflow: auto;
        max-height: calc(100vh - 230px);
    }

    .quick_coupon_block {
        margin: 12px 16px;
    }

    .total_cart_block {
        margin: 16px;
    }

    .quick-cart-wrapper {
        max-width: 350px;
        min-width: 350px;
    }

    .sub_total_cart {
        max-width: 350px;
        min-width: 350px;
    }

    .view_bag {
        min-width: 150px;
        max-width: 150px;
    }

    .checkout {
        min-width: 150px;
        max-width: 150px;

    }
}
</style>