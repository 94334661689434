<template>
    <div class="prod_box">
        <div class="row align-items-center my-2">
            <div class="col-3 text-center click" @click="diamondNavigate(item.diamond.slug)">
                <img class="img-fluid radious_four" height="280" width="280" :src="item.diamond.image"
                    alt="Diamond Image">
            </div>
            <div class="col-9">
                <div class="prod_title">
                    <div class="appr_price pe-3">
                        <div class="product_name" @click="diamondNavigate(item.diamond.slug)">
                            <h4 class="mb-1">{{ item.diamond.name }}</h4>
                        </div>
                        <div class="appraisal_title" v-if="item.appraisal === 1">
                            <div class="checkbox_custom ms-0">
                                <span :class="{ 'checked': item.appraisal === 1 }"></span>
                                <h4 class="mb-0">Appraisal Certificate ({{ $formatPrice(item.appraisalPrice)
                                    }})</h4>
                            </div>
                        </div>
                        <div class="price_product">
                            <h4 class="mb-0 price_tag">
                                {{ $formatPrice(item.diamond.price_label) }}
                            </h4>
                            <div @click="removeFromCart(item)" class="remove_item">
                                    <img width="20" height="24" class="default-icon" src="@/assets/payment_banner/quick_delete.svg" alt="delete_icon">
                                    <img width="20" height="24" class="hover-icon" src="@/assets/payment_banner/quick_delete_red.svg" alt="delete_icon_red">
                            </div>
                        </div>

                        <div class="avail_status mt-2"
                            v-if="item.diamond.is_duplicate === 1 || item.diamond.availability === 0">
                            <p class="mb-0">Please remove the diamond or choose a new diamond before submitting your
                                order.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script setup>
const localePath = useLocalePath()
const router = useRouter();
const { $formatPrice } = useNuxtApp()
const { item } = defineProps(['item'])
const emits = defineEmits(['removeFromCart', 'removeAppraisal', 'addAppraisal', 'update:cartVisible', 'closeCart'])

function removeFromCart(item) {
    emits('removeFromCart', item);
}

const diamondNavigate = (slug) => {
    const path = localePath('/loose-diamonds/' + slug);
    router.push(path);
};

</script>

<style scoped>
.avail_status p {
    font-size: 12px;
    color: rgb(255, 0, 0);
    font-weight: 600;
}

.avail_status p span {
    text-decoration: underline;
    cursor: pointer;
}

.appraisal_title h4 {
    font-size: 14px;
    color: #606060;
}
.price_product{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.price_tag {
    font-size: 14px;
    font-weight: 700;
    color: #353535;
    line-height: 1.7;
}

.product_name h4 {
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    color: #353535;
}

.remove_item,
.click {
    cursor: pointer;
}

.remove_item {
  display: inline-block;
}

.default-icon {
  display: block;
}

.hover-icon {
  display: none;
}

.remove_item:hover .default-icon {
  display: none;
}

.remove_item:hover .hover-icon {
  display: block;
}

.appraisal_title {
    margin: 8px 0;
    color: #606060;
}

.appraisal_title h4 {
    font-size: 14px;
}

.offer {
    color: #668b84;
    font-size: 16px;
    font-style: italic;
    margin: 8px 0 0 0;
}

@media(max-width: 767px) {
    .col-8 {
        padding-left: 0;
    }

    .offer {
        font-size: 14px;
    }
}
</style>
