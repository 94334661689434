<template>
    <div>
       <form @submit.prevent="Login">
           <div class="row">
               <div class="col-md-12 pb-3" v-if="errors">
                   <span class="error">{{ errors }}</span>
               </div> 
               <div class="col-md-12 pb-3 select_block_mod">
                   <label class="mb-1">Email Id</label>
                   <input name="email" autocomplete="off" required placeholder="" type="email" v-model="formData.email" class="" />
               </div>
               <div class="col-md-12">
                   <CommonBaseInputPassword name="password" value="" required mode="" placeholder="Password" v-model="formData.password" />
               </div>
               <div class="col-md-12 text-end py-2"><nuxt-link v-if="!['address', 'cart'].includes(page)" :to="localePath('/forgot-password')">Forgot your password?</nuxt-link></div>
               <div class="col-md-12 pt-2">
                   <button disabled v-if="showLoader">please wait...</button>
                   <button v-else type="submit">login</button>
               </div>
               <div class="col-md-12 text-center mt-3" v-if="toomany_req_error">
                   <span class="error">{{ toomany_req_error }}</span>
               </div>
           </div>
       </form>
       <div class="social-btns">
           <ClientOnly>   
               <!-- google btn :autoLogin="true"-->
               <!-- :prompt="true" : to enable one tap feature  :autoLogin="true"-->
               <GoogleLogin :prompt="true" :callback="handleGoogleLogin"  popup-type="TOKEN"  :error="handleGoogleError"  >
                    <div>
                        <p class="text-center m-0 mb-3 social-or">OR</p>
                    </div>
                    <button 
                        class="google-auth-btn auth-btn">
                        <img 
                            src="@/assets/svg/google.svg" 
                            alt="Google"
                            class="google-icon"
                            width="32"
                            height="32"
                        />
                        <span class="ms-2">{{ googleLoginLoader ? 'Signing in...' : 'Sign in with Google' }}</span>
                    </button>
                </GoogleLogin>
             

                <!-- <VFacebookLogin class="fb-btn" @sdk-init="handleSdk" @logout="handleLogout" @login="handleFacebookLogin" :app-id="config.public.facebookAppId">
                    <template #login="{loginWithFacebook}">
                        <button 
                            
                            :disabled="isLoading"
                            class="meta-auth-btn auth-btn"
                        >
                            <img 
                                    src="@/assets/svg/facebook.svg" 
                                    alt="Meta"
                                    class="meta-icon"
                                    width="32"
                                    height="32"
                                />
                            <span class="d-none d-md-block ms-2">{{ facebookLoginLoader ? 'Signing in...' : 'Sign in with Facebook' }}</span>
                        </button>
                    </template>
                </VFacebookLogin> -->
            </ClientOnly>
       </div>
    </div>
  </template>
  

<script setup>
    import { cartStore } from '@/stores/cart/cart';
    import setCsrfToken from '~/composables/csrf/setCsrfToken';
    import VFacebookLogin from 'vue-facebook-login-component-next'

    const { page } = defineProps(['page'])
    const localePath = useLocalePath()
    const { login, logout } = useSanctumAuth();
    const router = useRouter()
    const route = useRoute()
    // const route = useRoute()
    const {  $toast, $i18n, $registerGtag } = useNuxtApp()
    const store = cartStore()
    const config = useRuntimeConfig()
    const showLoader = ref(false)
    const formData = ref({
        email: '',
        password: '',
        remember: true
    })
    const errors = ref(null)
    const toomany_req_error = ref(false)
    const isLoading = ref(false)

    const googleLoginLoader = ref(false)
    const facebookLoginLoader = ref(false)


    const handleGoogleError = (error)=>{
        if(error?.type && error.type !== 'popup_closed'){
            errors.value = error.message
        }
    }

    const handleGoogleLogin = async (data) => {
        try {
           googleLoginLoader.value = true
           const res = await $fetch(useCountryPath('/auth/google/callback'), {
               method: 'POST',
               body: { ...data }
           })

           if(res){
                if(res?.data?.is_new_user){
                    $registerGtag({ status: "complete", data: res?.data });
                }
                $toast('success', 'Congratulations!', 'You are successfully logged in.')
                store.setCartCount()
               window.location.reload()
           }
          
        } catch (error) {
            // handle backend fail response
            errors.value = 'Something went wrong. Please try again later'
        }
        finally{
            googleLoginLoader.value = false
        }
    }

    
    async function Login(){
        showLoader.value = true;
        try {
            
            await setCsrfToken()
            await login({...formData.value,store: $i18n.locale.value})
            .then(()=>{

                let routes = ['login', 'register', 'forgot-password', 'reset-password']
    
                $toast('success', 'Congratulations!', 'You are successfully logged in.')
                store.setCartCount()
                if (routes.some(rt => route.name.includes(rt))) {
                    router.push(localePath({ name: 'account-detail' }));
                }else{
                    window.location.reload()
                }
            })


        } catch (error) {
            showLoader.value = false;
            if (error  && error.response?.status === 422) {
                errors.value = 'These credentials do not match our records.'
                return;
            }
            if (error && error.response?.status === 429) {
                toomany_req_error.value = "Too many requests, please try again after 60 seconds"
            }
            throw error
        }
    }

  
  
// export default {


//     methods: {
        
//         login() {

//             this.showLoader = true;

//             this.errors = null
//             this.toomany_req_error = false

//             this.formData = { ...this.formData, store : this.$i18n.locale }
            
//             const res = this.$auth.loginWith('laravelSanctum', { data: this.formData })
//                 .then((res) => {

                    
//                     this.showLoader = false;
                    

//                     this.$store.dispatch('getCartCount');
                    
//                     this.$toast('success', 'Congratulations!', 'You are successfully logged in.')

//                     let routes = ['login', 'register', 'forgot-password', 'reset-password']

//                     if (routes.some(route => this.$route.name.includes(route))) {
//                         this.$router.push(this.localePath({ name: 'account-detail' }));
//                     }

//                     if( res.data.message === 'Already logged in' ){

//                         window.location.reload()
//                     }
                   

//                 })
//                 .catch((error)=>{

//                     this.showLoader = false;
//                     if ( error.response?.status === 422) {
//                         // this.errors = error?.response?.data?.errors
//                         this.errors = 'These credentials do not match our records.'
//                         return;
//                     }

//                     if ( error.response?.status === 429) {
//                         this.toomany_req_error = "Too many requests, please try again after 60 seconds"
//                     }
                   
//                 });
//         } 
//     },
// }
    const handleFacebookLogin = async (response)=> {
        console.log(response,'login response')
         try {
            facebookLoginLoader.value = true
            const res = await $fetch(useCountryPath('/auth/facebook/callback'), {
                method: 'POST',
                body: { ...response }
            })

            if(res){
                if(res?.data?.is_new_user){
                    $registerGtag({ status: "complete", data: res?.data });
                }
                $toast('success', 'Congratulations!', 'You are successfully logged in.')
                store.setCartCount()
                window.location.reload()
            }
            
        } catch (error) {
            errors.value = 'Something went wrong. Please try again later'
        }finally{
            facebookLoginLoader.value = false
        }
    }

    const handleSdk = (res)=> {
        console.log(res,'sdk')
    }
    const handleLogout = (res)=> {
        console.log(res,'logout')
    }

</script>

<style scoped>

:global(.fb-btn){
    border: none !important;
    background-color: transparent;
    padding: 0;
}

:global(.fb-btn svg){
    /* border: none !important; */
    display: none;
}


input {
    border-radius: 4px ;
}

button {
    border-radius: 4px;
}
.user_block {
    margin-top: 6%;
}

span.error{
    color: rgba(255, 0, 0, 0.936);
}

a{
    color: #70c099;
    font-size: 14px;
}
h1.title {
    font-family: "NY600";
    font-size: 52px;
    line-height: normal;
}
.select_block_mod label {
    font-size: 14px;
    font-weight: 600;
    display: block;
    color: #363030;
}

.select_block_mod input {
    font-size: 16px;
    height: 45px;
    font-weight: 500;
    width: 100%;
    padding: 0 10px;
    outline: none;
}
.title_box {
    border-bottom: 1px solid #e2e2e2;
    padding-bottom: 3%;
    margin-bottom: 6%;
}
button{
    background: var(--green) !important;
    border-color: none;
    text-transform: uppercase;
    font-size: 14px !important;
    border: none;
    font-weight: 600;
    letter-spacing: 0.5px;
    line-height: 1;
    color: #fff;
    width: 100%;
    min-height: 46px;
}

.social-btns{
    display: flex;
    justify-content: center;
    flex-direction: column;
    /* gap: 15px; */
    margin-top: 16px;
}


.auth-btn{
    background: transparent !important;
    color: #363030;
    border: 1px solid #909090;
    display: flex;
    align-items: center;
    justify-content: center;
    /* max-width: 60px; */
}

.google-auth-btn{
    margin-bottom: 12px;    
}


.auth-btn span{
    font-size: 13px;
    letter-spacing: 1px;
    /* display: inline-block; */
    color: #363030;
}

.auth-btn:hover{
    background: rgba(240, 243, 243, 1) !important;
}

.auth-btn:hover span{
    /* color: #fff !important;     */
}

.social-or{
    color: #363030;
}



@media(max-width: 767px){
    h1.title{
        font-size: 30px;
    }
    .register_cta{
        text-align: left;
    }
    .user_block {
        /* margin-top: 20%; */
    }
}
</style>
