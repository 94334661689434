<template>
    <section :class="notCartClass">

        <div class="out_side" v-if="cleaningKit?.product_name">
            <div class="row">
                <div :class="{ 'col-3': page !== 'cart', 'col-md-3 col-4': page === 'cart' }">
                    <img src="/assets/cleaning_kit/kit.png" width="668" height="668" alt="cleaning_kit">
                    <div class="for_mobile mob_ctas" v-if="cleaningKit?.in_cart">
                        <div @click="removeFromCart(item)" class="edit">Remove</div>
                    </div>
                </div>

                
                <div class="" :class="{ 'col-9 ': page !== 'cart', 'col-md-9 col-8': page === 'cart' }">
                    <div class="prod_title">
                        <div class="appr_price d-flex justify-content-between" :class="{
                            'pe-3 align-items-top': page !== 'cart' && !cleaningKit?.in_cart,
                            'align-items-end pe-3': (page !== 'cart' && cleaningKit?.in_cart),
                            'align-items-top': (page === 'cart' && !cleaningKit?.in_cart),
                        }">
                            <div class="appr_price_left">
                                <div class="for_cart_page" v-if="page === 'cart'">
                                    <h4 class="font-active mb-2">{{ cleaningKit?.product_name }}
                                        <span v-if="!cleaningKit?.in_cart">
                                            (+{{ $formatPrice(cleaningKit?.variation?.[0]?.price) }})
                                        </span>
                                        <span v-if="cleaningKit?.in_cart"></span>
                                    </h4>
                                    <p class="view_details" @click="showModal()">VIEW DETAILS</p>
                                    <h4 class="mb-0 font-active quick_cart_price" :class="{ 'd-none': page === 'cart' }"
                                        v-if="cleaningKit?.variation && cleaningKit?.in_cart">
                                        {{ $formatPrice(cleaningKit?.variation?.[0]?.price) }}
                                    </h4>
                                </div>
                                <div class="for_quick_cart" v-if="page !== 'cart'">
                                    <h4 class="mb-2 quick_product_name">{{
                                        cleaningKit?.product_name
                                        }}
                                        <span v-if="!cleaningKit.in_cart">
                                            (+{{ $formatPrice(cleaningKit?.variation?.[0]?.price) }})
                                        </span>
                                        <span v-if="cleaningKit.in_cart"></span>
                                    </h4>
                                    <p class="view_details" @click="showModal()">VIEW DETAILS</p>
                                    <h4 class="mb-0 quick_cart_price" :class="{ 'd-none': page === 'cart' }"
                                        v-if="cleaningKit?.variation && cleaningKit?.in_cart">
                                        {{ $formatPrice(cleaningKit?.variation?.[0]?.price) }}
                                    </h4>
                                </div>

                            </div>

                            <div class="appr_price_right" v-if="!cleaningKit?.in_cart">
                                <div class="add_to_bag_outside">
                                    <div class="select_button" v-if="buttonLoader">
                                        <span>PLEASE WAIT</span>
                                    </div>
                                    <div v-else @click="addToCart()" class="select_button">
                                        <span>add to bag</span>
                                    </div>
                                </div>
                            </div>
                            <div v-if="cleaningKit.in_cart" class="appr_price_right">
                                <h4 class="mb-0 font-active quickcart" v-if="cleaningKit?.variation">
                                    {{ $formatPrice(cleaningKit?.variation?.[0]?.price) }}
                                </h4>
                                <div @click="removeFromCart(item)" class="remove_item"
                                    :class="{ 'd-none': page === 'cart' }">
                                    <!-- <SvgHeaderSvg :type="'quick_delete'" />
                                    <SvgHeaderSvg :type="'quick_red_delete'" /> -->
                                    <img width="20" height="24" class="default-icon"
                                        src="@/assets/payment_banner/quick_delete.svg" alt="delete_icon">
                                    <img width="20" height="24" class="hover-icon"
                                        src="@/assets/payment_banner/quick_delete_red.svg" alt="delete_icon_red">
                                </div>
                                <div class="mt-0 for_desktop  edit_remove">
                                    <div class="d-flex justify-content-end">
                                        <div class="edit" @click="removeFromCart(item)">Remove</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>

        <CommonCustomModal v-model="flagShowModal" :size="'lg'">
            <div>
                <section class="modal_section">
                    <div class="title">
                        <h5 class="font-active mb-0" v-if="cleaningKit?.product_name">{{ cleaningKit?.product_name }}
                        </h5>
                    </div>

                    <div class="cleaning_info">
                        <p class="mb-0 common_padding">
                            Introducing our Jewelry Cleaning Kit—the perfect add-on to keep your treasured pieces
                            sparkling
                            like
                            new! For just $50, this complete kit includes everything you need to care for your jewelry:
                        </p>

                        <div class="img_box common_padding">
                            <div class="row align-items-center">
                                <div class="col-5">
                                    <img src="/assets/cleaning_kit/bottle.png" alt="cleaning kit" width="536"
                                        height="680">
                                </div>
                                <div class="col-1"></div>
                                <div class="col-5">
                                    <img src="/assets/cleaning_kit/kit.png" width="668" height="668" alt="cleaning_kit">
                                </div>
                            </div>
                        </div>

                        <div class="cleaning_list common_padding">
                            <ul>
                                <li>A microfiber cloth for gentle polishing.</li>
                                <li>A diamond cleaning pen for precision cleaning.</li>
                                <li>A jewelry cleaning solution to restore shine.</li>
                                <li>A brush to reach delicate areas.</li>
                            </ul>
                        </div>

                        <div class="mid_title common_padding">
                            <h5>Why Choose Our Kit?</h5>

                            <p class="mb-0">Jewelry deserves special care, and our cleaning kit is tailored to keep your
                                pieces
                                looking like new. Regular use will help prevent the buildup of dirt and tarnish,
                                prolonging
                                the
                                beauty of your jewelry. With this kit, maintaining your jewelry’s sparkle will never be
                                easier!
                            </p>
                        </div>

                        <span class="cleaning_note">Please note: This kit is available exclusively as an add-on and the
                            components cannot be purchased separately.
                        </span>


                    </div>


                </section>


                <div class="scroll_padding"></div>


                <div class="sticky_btnn">

                    <div class="cleaning_bag_button">

                        <!-- <div class="select_button" v-if="buttonLoader">
                                <span>PLEASE WAIT...</span>
                            </div> -->
                        <!-- <div @click="addToCart()" class="select_button btn-p-animation">
                                <span>add to bag</span>
                        
                        </div> -->
                    </div>
                </div>
            </div>
        </CommonCustomModal>

    </section>
</template>

<script setup>
const { page, id, item, cleaningKit } = defineProps(['item', 'id', 'page','cleaningKit']);

const flagShowModal = ref(false);

const { $toast } = useNuxtApp();

const buttonLoader = ref(false);
const viewerror = ref(null);
const messageError = ref([]);

const showModal = () => {
    flagShowModal.value = true;
};

const emits = defineEmits(['removeFromCart', 'addCleaningKit', 'updateCleaning'])

const removeFromCart = (item) => {
    emits('removeFromCart', item)
}

const addToCart = async () => {
    const data = ref({ item_id: cleaningKit?.id })
    try {
        buttonLoader.value = true;
        const res = await $fetch(useCountryPath('/simple/add/session'), {
            method: 'POST',
            body: data.value
        })
        if (res.success) {
            $toast('success', 'Congratulations!', 'Item added to cart');
            emits('updateCleaning')
            emits('addCleaningKit');
        }
    }
    catch (error) {
        if (error?.status === 422) {
            messageError.value = error?.data?.errors?.item_id[0];
            $toast('alert', 'Alert!', 'Something Went Wrong, Please Try Again Later');
            scrollToError()
        }
        else if (error?.status === 400) {
            messageError.value = error?.data?.errors;
            scrollToError()
        }
        else {
            $toast('alert', 'Alert!', 'Something Went Wrong, Please Try Again Later');
            console.log(error, 'Failed adding cleaning kit to the cart');
        }
    }
    finally {
        if (!cleaningKit?.in_cart) {
            setTimeout(() => {
                buttonLoader.value = false;
            }, 650);
        }
        data.value.item_id = '';
    }
};

const notCartClass = computed(() => {
    return page !== 'cart' ? 'not-cart-page' : '';
});

const scrollToError = async () => {
    await nextTick();
    if (viewerror.value) {
        const dropdownEl = viewerror.value.$el || viewerror.value;
        dropdownEl.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
};

</script>


<style scoped>
.out_side_title {
    font-weight: 400;
    font-size: 18px;
    letter-spacing: 0;
    margin-bottom: 12px;
    /* color: #405955; */
    color: #212529;
}

.row {
    align-items: center;
}

.out_side_text {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0;
    color: #606060;
}

.appr_price_right h4, .appr_price_right p {
    text-align: right;
}

.out_side_text span {
    cursor: pointer;
}

.data_part {
    margin-top: 12px;
}

.know_more,
.remove {
    font-size: 14px;
    text-decoration: underline;
    color: #606060;
    cursor: pointer;
    font-weight: 500;
    letter-spacing: 1.5px;
    text-transform: uppercase;
}

.pipe {
    color: #c4c4c4;
    margin: 0 8px;
}

.remove {
    color: #d20000;
}

.title h5 {
    font-weight: 400;
    font-size: 24px;
    letter-spacing: 0;
    color: #000000;
}

.common_padding {
    margin: 20px 0;
}

.cleaning_list ul {
    margin: 0;
}

ul li {
    color: #353535;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0;
}

.cleaning_info p {
    font-size: 16px;
    color: #606060;
}

.error_msg {
    font-size: 14px;
    color: red;
}

.mid_title h5 {
    color: #353535;
    font-size: 18px;
    font-weight: 600;
}

.cleaning_note {
    font-weight: 400;
    font-style: italic;
    font-size: 16px;
    letter-spacing: 0;
    color: #353535;
}
.add_to_bag_outside .select_button{
    color: #668B84;
    background: none;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    text-decoration: underline;
    text-align: right;

}
.select_button{
    background: var(--green);
    color: #fff;
    /* height: 45px; */
    /* display: flex;
    align-items: center;
    justify-content: center; */
    text-transform: uppercase;
    letter-spacing: 2px;
    /* border-radius: 4px; */
    font-size: 14px;
    font-weight: 600;
    width: 100%;
    cursor: pointer;
}

.cleaning_info {
    margin-bottom: 12px;
}

.sticky_btnn {
    position: sticky;
    bottom: -20px;
    left: 0;
    width: calc(100% + 55px);
    margin-left: -30px;
    margin-bottom: -20px;
}

.scroll_padding {
    padding-bottom: 15px;
}

.price_product {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
}

.edit {
    color: #6a6161;
    font-size: 14px;
    font-weight: 500;
    text-decoration: underline;
    cursor: pointer;
}

.remove_item {
    cursor: pointer;
    text-decoration: underline;
    text-transform: capitalize;
    color: #6a6161;
    font-size: 14px;
    font-weight: 500;
}

.view_details{
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    text-decoration: underline;
    cursor: pointer;
    color: #606060;
    margin: 0;
}

/* QUICK CART CSS */
section.not-cart-page.prod_block{
    padding: 0;
}

.not-cart-page .view_details{
    font-size: 12px;
    margin-bottom: 4px;
}

.not-cart-page .appr_price_left{
    width: 60%;
}

.not-cart-page .appr_price_right {
    width: 40%;
    text-align: end;
}

.not-cart-page .appr_price.d-flex.align-items-top.justify-content-between{
    align-items: center;
}

.not-cart-page .appr_price h4.mb-0.quickcart{
    display: none;
}

.not-cart-page .edit_remove{
    display: none;
}

.remove_item,
.clic {
    cursor: pointer;
}

.remove_item {
    display: inline-block;
}

.default-icon {
    display: block;
}

.hover-icon {
    display: none;
}

.remove_item:hover .default-icon {
    display: none;
}

.remove_item:hover .hover-icon {
    display: block;
}

.not-cart-page .row{
    align-items: center;
}

/* font size for quick cart */
.not-cart-page .appr_price_left .for_quick_cart .quick_product_name{
    /* font-size: 14px;
    font-weight: 700;
    color: #353535; */
    font-size: 14px;
    font-weight: 400;
    color: #353535;
    cursor: pointer;
}
.not-cart-page .appr_price_left .for_quick_cart .quick_cart_price{
        font-size: 14px;
        font-weight: 700;
        color: #353535;
        line-height: 1.7;
}

.message_error{
    font-size: 12px;
    color: red;
}
/*  */

@media(max-width: 992px) {
    .appr_price_left {
        width: 65%;
    }

    .appr_price_right {
        width: 35%;
    }
}

@media (max-width : 767px) {
    .sticky_btnn {
        position: sticky;
        bottom: -15px;
        left: 0;
        width: calc(100% + 30px);
        margin-left: -15px;
    }

    .out_side_title {
        font-size: 16px;
        margin-bottom: 8px;
    }

    .out_side_text {
        font-size: 12px;
    }

    .know_more,
    .remove {
        font-size: 12px;
        letter-spacing: 1px;
    }

    .add_to_bag_outside .select_button {
        font-size: 12px;
        letter-spacing: 1px;
    }

    .title h5 {
        font-size: 18px;
        color: #000000;
    }

    .cleaning_info p {
        font-size: 14px;
        color: #606060;
    }

    .error_msg {
        font-size: 12px;
    }

    .common_padding {
        margin: 16px 0;
    }

    ul li {
        color: #353535;
        font-size: 14px;
    }

    .mid_title h5 {
        font-size: 14px;
    }

    .cleaning_note {
        font-size: 14px;
    }

    .col-8 {
        padding-left: 0;
    }

    .view_details {
        font-size: 12px;
    }

    .edit_remove {
        color: #6a6161;
        font-size: 14px;
    }

    .mob_ctas {
        color: var(--green);
        font-size: 14px;
        text-align: center;
    }

    .not-cart-page .mob_ctas {
        display: none !important;
    }

    .mob_ctas .edit {
        color: #668b84;
        text-decoration: none;
        font-weight: 600;
    }

    .offer {
        font-size: 14px;
    }
    .row{
        align-items: flex-start;
    }
    /* QUICK CART  */
    .not-cart-page .add_to_bag_outside .select_button {
        font-size: 12px;
        letter-spacing: 1px;
    }
}


</style>