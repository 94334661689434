export default defineNuxtPlugin((nuxtApp) => {
	const runtimeConfig = useRuntimeConfig()

	const initiateTrustPilot = async () => {
		return
		if (window.Trustpilot === undefined) {
		const script = document.createElement('script');
		script.src = 'https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js';
		script.defer = true;
		script.async = true;
		document.body.appendChild(script);

		script.onload = () => {
			if (typeof TP !== "undefined" && TP?.widget?.refresh) {
			TP.widget.refresh();
			}

			if (typeof TP !== "undefined" && TP?.TrustBox?.load) {
			TP.TrustBox.load();
			}
		}
		}
	}

	const initiateTawkWidget = async () => {
		
		const tawk_url = 'https://embed.tawk.to/5ea2ea2369e9320caac6f331/default';

		// Tawk.to widget
		window.Tawk_API = window.Tawk_API || {};
		window.Tawk_LoadStart = new Date();

		const s1 = document.createElement('script');
		s1.async = true;
		s1.src = tawk_url;
		s1.charset = 'UTF-8';
		s1.setAttribute('crossorigin', '*');

		const s0 = document.getElementsByTagName('script')[0];
		s0.parentNode.insertBefore(s1, s0);

		const sendUserDetailsToApi = (data) => {
		const userData = data;
		nuxtApp.$axios.post(`${runtimeConfig.public.apiPath}tawkchat`, userData)
			.then(response => {
			// Handle response if needed
			})
			.catch(error => {
			console.error('Error sending user details to API:', error);
			});
		};

		window.Tawk_API.onOfflineSubmit = function (data) {
		sendUserDetailsToApi(data);
		};

		window.Tawk_API.onPrechatSubmit = function (data) {
		sendUserDetailsToApi(data);
		};
	}

	const initiateAffirm = async () => {
		
		if (window.affirm !== undefined) {
			return;
		}

		const _affirm_config = {
			public_api_key: runtimeConfig.public.affirmKey,
			script: runtimeConfig.public.affirmUrl,
		};

		(function (l, g, m, e, a, f, b) {
		var d, c = l[m] || {},
			h = document.createElement(f),
			n = document.getElementsByTagName(f)[0],
			k = function (a, b, c) { return function () { a[b]._.push([c, arguments]) } };
		c[e] = k(c, e, "set");
		d = c[e];
		c[a] = {};
		c[a]._ = [];
		d._ = [];
		c[a][b] = k(c, a, b);
		a = 0;
		for (b = "set add save post open empty reset on off trigger ready setProduct".split(" "); a < b.length; a++) d[b[a]] = k(c, e, b[a]);
		a = 0;
		for (b = ["get", "token", "url", "items"]; a < b.length; a++) d[b[a]] = function () { };
		h.async = true;
		h.src = g[f];
		n.parentNode.insertBefore(h, n);
		delete g[f];
		d(g);
		l[m] = c;
		})(window, _affirm_config, "affirm", "checkout", "ui", "script", "ready");
	}

	const initiateHotjar = async () => {
		
		(function (h, o, t, j, a, r) {
		h.hj = h.hj || function () { (h.hj.q = h.hj.q || []).push(arguments) };
		h._hjSettings = { hjid: 4946101, hjsv: 6 };
		a = o.getElementsByTagName('head')[0];
		r = o.createElement('script'); r.async = 1;
		r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
		a.appendChild(r);
		})(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
	}

	nuxtApp.hook('app:mounted', () => {
		const { user, isAuthenticated } = useSanctumAuth();
		initiateAffirm().catch(console.error);
		initiateTrustPilot().catch(console.error);

		if (nuxtApp.$country.location === 'US') {
			setTimeout(() => initiateTawkWidget().catch(console.error), 5000);
		}
		if(isAuthenticated.value){
			const emailIds = ['poddardiamonds', 'friendlydiamonds', 'etmedialabs']
			const containsKeyword = emailIds.some(email => user.value.email.includes(email));
			if(containsKeyword){
				return
			}
		}
		setTimeout(() => initiateHotjar().catch(console.error), 1000);
	});
	
});
