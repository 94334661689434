<template>
    <!-- :class="{'sub_menu_parent': !isAuthenticated, 'sub_menu_parent_mod': isAuthenticated}" -->
    
        <div class="sub_menu_parent" v-if="loader">
            <NuxtLink @click="$emit('hideShowMenu')" class="for_desktop_mod hover_cta" :to="localePath('/about-us')">About </NuxtLink>
        </div>
        
      <div class="sub_menu_parent" v-else :class="{'sub_menu_parent': !isAuthenticated, 'sub_menu_parent_mod': isAuthenticated}" >
            <NuxtLink @click="$emit('hideShowMenu')" class="for_desktop_mod hover_cta" :to="localePath('/about-us')">About </NuxtLink>
            <div class="for_mobile_mod" @click="toggleMenu()">
                <div class="link_with_arrow">
                    <span>About</span>
                    <!-- <SvgHeaderSvg class="headdown"  :type="'down'" /> -->
                    <SvgHeaderSvg v-show="!isToggle" :type="'down'" />
                    <SvgHeaderSvg v-show="isToggle" :type="'up'" />
                </div>
            </div>
            <div ref="main_menu_box" class="main_menu_box" :class="{ 'open_for_desktop' : hoverFlag === 'about','hide-menu': isHideMenu  }">
             
                <!-- ssr-only :hydratable="true" :hydrate-on-visible="true" -->
                <div class="inner_menu">
                    <div class="landing_link inner_menu_box for_mobile_mod">
                        <NuxtLink @click="$emit('hideShowMenu')" :to="localePath('/about-us')">About</NuxtLink>
                    </div>
                    <div class="inner_menu_box inner_menu_four">
                        <div class="menu_card">
                            <h3>About </h3>
                            <div class="menu_ordered menu_ordered_noicon">
                                <NuxtLink @click="$emit('hideShowMenu')" :to="localePath('/our-story')">Our Story </NuxtLink>
                                <NuxtLink @click="$emit('hideShowMenu')" :to="localePath('/ethical-and-conflict-free-diamonds')">Ethical & Conflict free Diamond</NuxtLink>
                                <NuxtLink @click="$emit('hideShowMenu')" :to="localePath('/friendly-diamond-difference')">The Friendly Diamonds Difference </NuxtLink>
                                <NuxtLink @click="$emit('hideShowMenu')" :to="localePath('/green-and-sustainable-gift')" v-if="$country.evertreen">Giving Back to Our Planet </NuxtLink>
                                <NuxtLink @click="$emit('hideShowMenu')" :to="localePath('/our-share-of-care')">Giving Back To The Community</NuxtLink>
                                <NuxtLink @click="$emit('hideShowMenu')" :to="localePath({ name: 'blog-listing' })">Blog</NuxtLink>
                                <NuxtLink @click="$emit('hideShowMenu')" :to="localePath('/testimonials')">Testimonials</NuxtLink>
                                <NuxtLink @click="$emit('hideShowMenu')" :to="localePath('/customer-service')">Services & Values</NuxtLink>
                                <NuxtLink @click="$emit('hideShowMenu')" :to="localePath('/press')">Press</NuxtLink>
                                <NuxtLink @click="$emit('hideShowMenu')" :to="localePath('/customer-service/faq')" v-if="!$country.is_india">FAQs</NuxtLink>
                            </div>
                        </div>
                        
                    </div>
                    
                    <div class="inner_menu_box inner_menu_four">
                        <div class="menu_card">
                            <h3>Contact</h3>
                            <div class="menu_ordered menu_ordered_noicon">
                                <NuxtLink @click="$emit('hideShowMenu')" :to="localePath('/contact-us')">Contact Us </NuxtLink>
                                <NuxtLink @click="$emit('hideShowMenu')" :to="localePath('/appointment')">Book an appointment</NuxtLink>
                            </div>
                        </div>
                    </div>
                    <div class="inner_menu_box inner_menu_four">
                        <div class="menu_card menu_card_no_border">
                            <h3>Education </h3>
                            <div class="menu_ordered menu_ordered_noicon">
                                <NuxtLink @click="$emit('hideShowMenu')" :to="localePath('/4-cs-diamond')">4 C's of a lab diamond </NuxtLink>
                                <NuxtLink @click="$emit('hideShowMenu')" :to="localePath('/engagement-ring-guide')">Engagement Ring Guide </NuxtLink>
                                <NuxtLink @click="$emit('hideShowMenu')" :to="localePath('/how-to-find-ring-size')">Ring Size Guide </NuxtLink>
                            </div>
                        </div>
                    </div>
                    <div class="inner_menu_box inner_menu_four inner_menu_four_mod">
                        <div class="menu_card">
                            <div class="main_menu_image">
                                <picture>
                                <source width="249" height="304" type="image/webp" srcset="~/assets/header/menu_about.webp">
                                <img class="radious_eight" loading="lazy" width="249" height="304" type="image/jpeg" src="~/assets/header/menu_about.jpg" alt="About us">
                                </picture>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</template>

<script setup>
    import setCsrfToken from '~/composables/csrf/setCsrfToken';


    const localePath = useLocalePath()
    const { $country } = useNuxtApp()
    const { login, logout, isAuthenticated  } = useSanctumAuth();

    async function loggedout(){
            await setCsrfToken()
            await logout()
    }

    const props = defineProps([
      'openOverlay',
      'hoverFlag',
      'locale',
      'subMenuState',
      'menuName',
      'isHideMenu'
    ]);

    const hideMenu = ref(false);
    const openMainMenu = ref(false);
    const arrow = ref(true);
    const main_menu_box = ref(null)
    const toggleState = toRef(props.subMenuState)

    const isToggle = computed(()=>{
        return toggleState.value[props.menuName]
    })

    const loader = ref(true)

    const route = useRoute();
    const emit = defineEmits(['hideMenuInDesktop','handleSubMenuState','hideShowMenu'])


    // watch(route, (to, from) => {
    //   hideMenu.value = true;
    //   setTimeout(() => {
    //     hideMenu.value = false;
    //   }, 500);
    //   emit('hideMenuInDesktop');
    // });

    watch(() => props.openOverlay, (newVal, oldVal) => {
      if (!oldVal) {
        // arrow.value = true;
        // openMainMenu.value = false;
        emit('handleSubMenuState','');
       
      }
    });

    watch(isToggle,(oldVal,newVal)=>{
        nextTick(()=>{
                main_menu_box.value.style.display  = isToggle.value ? 'block' : 'none' 
          })
    })

    const toggleMenu = () => {
    //   arrow.value = !arrow.value;
    //   openMainMenu.value = !openMainMenu.value;
      emit('handleSubMenuState','about');
    };

    defineOptions({
      name: 'AboutMenu',
      serverCacheKey: props => `AboutMenu::${props.locale}`,
    });
    onMounted(() =>{
        loader.value = false
    })
</script>

<style scoped>
.landing_link .inner_menu_box{
    padding: 10px 0 !important;
} 
.main_menu_block ul li a {
    color: #303030;
    font-size: 16px;
    line-height: 1.25;
    text-transform: capitalize;
    font-weight: 500;
    display: inline-block;
}
.inner_menu_box .menu_card{
    border-top: none;
}
.menu_ordered{
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}
</style>