export default {
    setcartCount(state, posts) {
        state.cartCount = posts;
    },
    setCartListing(state, posts) {
        state.cartData = posts;
    },
    setCheckoutListing(state, posts) {
        let lineItems = [];
         posts.items.filter((item) => {
            // console.log(item.type)
            if(item.type === 'loose') {
                lineItems.push({
                    name: item.diamond.name,
                    quantity: "1",
                    unitAmount: item.diamond.price_label,
                    kind: "debit",
                    unitTaxAmount: "",
                    description: item.diamond.info,
                    productCode: item.id
                })
            }
            if(item.type === 'preset') {
                lineItems.push({
                    name: item.setting.product_name,
                    quantity: "1",
                    unitAmount: item.setting.price_label,
                    kind: "debit",
                    unitTaxAmount: "",
                    description: item.setting.d_info,
                    productCode: item.id
                })
            }
            if(item.type === 'shop') {
                lineItems.push({
                    name: item.item.product_name,
                    quantity: "1",
                    unitAmount: item.item.price_label,
                    kind: "debit",
                    unitTaxAmount: "",
                    description: item.item.info,
                    productCode: item.id
                })
            }

            if(['cyor', 'cyop'].includes(item.type)) {
                lineItems.push({
                    name: item.setting.product_name,
                    quantity: "1",
                    unitAmount: item.setting.price_label + item.appraisalPrice + item.diamond.price_label,
                    kind: "debit",
                    unitTaxAmount: "",
                    description: item.setting.description,
                    productCode: item.id
                })
            }

            if(['cyoe'].includes(item.type)) {
                lineItems.push({
                    name: item.setting.product_name,
                    quantity: "1",
                    unitAmount: item.setting.price_label + item.appraisalPrice + item.diamond_two.price_label,
                    kind: "debit",
                    unitTaxAmount: "",
                    description: item.setting.description,
                    productCode: item.id
                })
            }
            
        });
       
        console.log('posts', lineItems);
        state.cartCheckoutListing = lineItems;
    },
    setCartTotal(state, posts) {
        let cart_total = {
          subtotal: posts.subtotal_label,
          shipping: posts.shipping,
          discount_label: posts.discount_label,
          discount: posts.discount,

          coupon_label: posts.coupon?.coupon_discount_label
            ? posts.coupon?.coupon_discount_label
            : 0,
          coupon_discount: posts.coupon?.coupon_discount
            ? posts.coupon?.coupon_discount
            : 0,

          tax_percent_label: posts.tax_percent_label,
          tax_label: posts.tax_label,

          partially_paid: posts.partially_paid,

          couponData: posts.coupon,

          total: posts.total_label,

          total_saving: posts.total_saving_label,

          subtotal_saving: posts.subtotal_saving,

          coupon_code: posts.coupon.coupon,

          eta: posts.eta,

          split: posts.split,
          is_duplicate: posts.is_duplicate,
          availability: posts.availability,
          availability: posts.availability,
          partially_paid: posts.partially_paid,
          has_free_product: posts.has_free_product,
          free_product: posts.free_product,
          before_christmas: posts.before_christmas,
          before_new_year: posts.before_new_year,
        
        };
        state.cartTotal = cart_total;
    },
    removeFromCart(state, id){
        const index = state.cartData.items.findIndex(item => item.id === id)
        if (index !== -1) {
            state.cartData.items.splice(index, 1)
        }
    },
    updateAppraisal(state, id){
        state.cartData.items.filter((obj)=> {
            if(obj.id === id){
                if(obj.appraisal === 1){
                    obj.appraisal = 0
                }else{
                    obj.appraisal = 1
                }
            }
        });
        
    },
    changeCurrency(state, currency) {
        state.currency = currency;
    },
    setCartVisible(state, cartVisible){
        // console.log(`cart see before: ${cartVisible}`);  
        state.cartVisible = cartVisible;
        // console.log(`cart see after now: ${state.cartVisible}`); 
    }
    

}