<template>
   
      <svg class="svg-head" v-html="svgIcon"></svg>
    
</template>
  
<script setup>
  
    const props = defineProps(['type']);
  
    const headerSvgs = [
        'email', 'telephone', 'chat', 'whatsapp', 'virtual', 'user', 'cart','cart_white', 'cart_mobile', 'wishlist',
        'wishlist-fill', 'order', 'close', 'search', 'right', 'down', 'up', 'left', 'star', 'loader',
        'eye-open', 'eye-close', 'info', 'check', 'plus', 'minus', 'list','oldlist', 'grid','grid_two' ,'loader_mob', 'ask','ask_white',
        'reply','chev_right','chev_left', 'arrow_blog', 'filter', 'filter_burger', 'logout', 'hamburger','delete','checkout','open','mini_checkout','close_cart','quick_delete',
        'quick_red_delete'
    ];
  
  
    const svgIcon = computed(() => {
        if (headerSvgs.includes(props.type)) {
            return `<use xlink:href="/svg/header.svg#${props.type}"> </use>`;
        } 
    });
</script>
  
<style scoped>
/* Added the below code as it was not getting applied for svg headers component issuse commented out the one which was */
.arrow_eng .svg-head {
    height: 13px;
    width: 12px;
    margin: 0 10px;
}


/*   .svg-head {
        width: 24px;
        height: 24px;
    }
    */
</style>
  