<template>
    <div class="coverup">
            <div class="title_block" @click="openOffers">
                <h4 class="mb-0 font-active" >
                    Offers
                </h4>
                <SvgHeaderSvg :type=" isVisible ? 'down' : 'up'"/>
            </div>
            <transition name="accordion" @before-enter="beforeEnter" @enter="enter" @leave="leave">
                <div v-if="isVisible">
                    <div class="offer_box">
            
                        <div class="offer_one">
                            <div class="row align-items-center">
                                <div class="col-3">
                                    <div class="img_block text-center">
                                        <img height="280" width="280" src="/assets/free-product/pendant.png" alt="Product Image">
                                    </div>
            
                                </div>
                                <div class="col-9 text">
                                    <p class="mb-0">
                                        Free 1/2 Ct. Gold Solitaire Necklace on a spend of <span class="offer_price">$3000</span> or
                                        more.
                                    </p>
                                </div>
                            </div>
                        </div>
            
                        <div class="offer_one">
                            <div class="row align-items-center">
                                <div class="col-3">
                                    <div class="img_block text-center">
                                        <img height="280" width="280" src="/assets/free-product/stud.png" alt="Product Image">
                                    </div>
                                </div>
                                <div class="col-9 text">
                                    <p class="mb-0">
                                        Free 1 Ct. Gold Stud Earrings on a spend of <span class="offer_price">$5000</span> or more.
                                    </p>
                                </div>
                            </div>
                        </div>
            
                        <div class="offer_one">
                            <div class="row align-items-center">
                                <div class="g-0 col-3">
                                    <div class="newText text-center font-active">
                                        $50
                                    </div>
                                </div>
                                <div class="col-9 g-0 text coupon">
                                    <p class="mb-0">
                                        Get $50 off on your first purchase.
                                    <br>  Use code <span>‘FD50’ </span>during checkout.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
    </div>
</template>



<script setup>
const props = defineProps(['page', 'coupon','item'])
const isVisible = ref(false);

const emit = defineEmits(['page', 'applyCoupon', 'isCouponVisible'])
function beforeEnter(el) {
    el.style.height = "0";
}
function enter(el) {
    // Set height to the scrollHeight to smoothly transition to open state
    el.style.height = el.scrollHeight + "px";
}
function leave(el) {
    // Set height back to 0 when closing
    el.style.height = "0";
}
const openOffers = () => {
    isVisible.value = !isVisible.value;
}

</script>

<style scoped>
.title_block {
    padding: 8px 16px;
    background: #F5F5F5;
    display: flex;
    cursor: pointer;
    border: 1px solid #668B8426;
    justify-content: space-between;
    align-items: center;
}

.title_block h4 {
    font-size: 16px;
    font-weight: 400;
}
.offer_box {
    background: #FFFFFF;
    padding: 16px;
    border-bottom: 1px solid #c4c4c4;
}

.offer_one {
    padding: 8px 12px;
    background-color: rgba(102, 139, 132, 0.15);
    border: 1px solid #668B8426;
    border-radius: 4px;
}

.offer_one+.offer_one {
    margin-top: 16px;
}

.img_block {
    border-radius: 4px;
}

.coupon_copy{
    cursor: pointer;
    position: relative;
}

.copied{
    position: absolute;
    top: 25px;
    right: 0;
    font-size: 12px;
    color: #606060;
}

.col-8 {
    padding-left: 0;
}
.newText{
    font-size: 32px;
    color: #405955;
}
.text.coupon{
    padding: 0 16px 0;
}
.text p {
    font-size: 14px;
    font-weight: 500;
    color: #405955;
    text-align: left;
}

.text span {
    font-size: 14px;
    color: #405955;
    font-weight: 700;
}

@media (max-width: 767px) {
    .col-8 {
        padding-left: 0;
    }

    .title_block h4 {
        font-size: 16px;
    }
    .text.coupon{
        padding: 0 8px 0;
    }
    .newText{
        font-size: 30px;
        color: #405955;
    }
}

.accordion-enter-active, .accordion-leave-active {
  transition: height 0.3s ease-in-out;
  overflow: hidden;
}
.accordion{
    transition: 0.4s;
}

.accordion-enter, .accordion-leave-to{
  height: 0;
  transition: 0.6s ease-in-out;
}

</style>