export default defineNuxtPlugin((nuxtApp) => {

    const setAnalytics = async () => {
        let currencyCode;
        const runtimeConfig = useRuntimeConfig()
        
        // Wait for the app to be ready
        nuxtApp.hook('app:mounted', () => {
        
            //------------- load google tag manager script -------------//
            try{
                (function(w, d, s, l, i) {
                    w[l] = w[l] || [];
                    w[l].push({
                    'gtm.start': new Date().getTime(),
                    event: 'gtm.js'
                    });
                    const f = d.getElementsByTagName(s)[0],
                        j = d.createElement(s),
                        dl = l !== 'dataLayer' ? '&l=' + l : '';
                    j.async = true;
                    j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
                    f.parentNode.insertBefore(j, f);
                })(window, document, 'script', 'dataLayer', useRuntimeConfig().public.gtagKey);
            }
            catch(error){
                console.log('gtag error', error)
            }

            // fb ind
            if( nuxtApp.$country.location == 'IN' &&  ![false, 'false'].includes(runtimeConfig.public.fbTag)){
                
                !function(f,b,e,v,n,t,s)
                {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                n.queue=[];t=b.createElement(e);t.async=!0;
                t.src=v;s=b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t,s)}(window, document,'script',
                'https://connect.facebook.net/en_US/fbevents.js');
                fbq('init', runtimeConfig.public.fbTag);
                fbq('track', 'PageView');

            }

            if( nuxtApp.$country.location == 'IN' && ![false, 'false'].includes(runtimeConfig.public.gtagINKey)){
                
                (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer', runtimeConfig.public.gtagINKey);

            }

            //tiktok
            if( nuxtApp.$country.location == 'US' ){

                !function (w, d, t) {
                    w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie","holdConsent","revokeConsent","grantConsent"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(
                  var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var r="https://analytics.tiktok.com/i18n/pixel/events.js",o=n&&n.partner;ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=r,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};n=document.createElement("script")
                  ;n.type="text/javascript",n.async=!0,n.src=r+"?sdkid="+e+"&lib="+t;e=document.getElementsByTagName("script")[0];e.parentNode.insertBefore(n,e)};
                    ttq.load('CRMM1MBC77U40MO99EJG');
                    ttq.page();
                  }(window, document, 'ttq');
            }


            if( nuxtApp.$country.location == 'US' ){
                const clickads_token = runtimeConfig.public.clickads_token
                if([false, 'false'].includes(clickads_token)){
                    return
                }
                !function(s,a,e,v,n,t,z)
                {
                    if(s.saq)return;
                    n=s.saq=function(){
                        n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)
                    };
                    if(!s._saq)s._saq=n;n.push=n;n.loaded=!0;n.version='1.0';n.queue=[];t=a.createElement(e);t.async=!0;t.src=v;z=a.getElementsByTagName(e)[0];z.parentNode.insertBefore(t,z)
                }
                (window,document,'script','https://tags.srv.stackadapt.com/events.js');saq('ts', clickads_token);
            }


            currencyCode = nuxtApp.$country.currency_sign;


        });
        
         //------------- Facebook: page view -------------//
         nuxtApp.provide('PageView', (data) => { 
            trigerGtagUtils('PageView', data)
        })

        //------------- Register GTAG -------------//
        nuxtApp.provide('registerGtag', (data) => { 
            trigerGtagUtils('registerGtag', data)
        })

        // inject trigerTT(eventName, data)
        nuxtApp.provide('trigerTTViewContent', (eventName, data) => {
            trigerGtagUtils(eventName, data)
        })

        //------------- Social media GTAG -------------//
        nuxtApp.provide('socialGtag', (status) => { 
            trigerGtagUtils('socialGtag', status)
        })

        //------------- newsletter GTAG -------------//
        nuxtApp.provide('newsletterGtag', () => { 
            trigerGtagUtils('newsletterGtag', false)
        })

        //------------- cyo choose setting GTAG -------------//
        nuxtApp.provide('choosesettingGtag', (moduleSubType) => { 
            trigerGtagUtils('choosesettingGtag', moduleSubType)
        })

        //------------- cyo select setting GTAG -------------//
        nuxtApp.provide('selectsettingGtag', (moduleSubType) => { 
            trigerGtagUtils('selectsettingGtag', moduleSubType) 
        })

        //------------- cyo choose diamond GTAG -------------//
        nuxtApp.provide('choosediamondGtag', (moduleSubType) => { 
            trigerGtagUtils('choosediamondGtag', moduleSubType) 
        })

        //------------- cyo select diamond GTAG -------------//
        nuxtApp.provide('selectdiamondGtag', (moduleSubType) => { 
            trigerGtagUtils('selectdiamondGtag', moduleSubType) 
        })

        //------------- cyo complete page  GTAG -------------//
        nuxtApp.provide('loadcompleteGtag', (moduleSubType) => { 
            trigerGtagUtils('loadcompleteGtag', moduleSubType) 
        })

        //------------- cyo add to cart  GTAG -------------//
        nuxtApp.provide('cyotoCartGtag', (moduleSubType, data) => { 
            trigerGtagUtils('cyotoCartGtag', {'moduleSubType': moduleSubType, ...data}) 
        })

    
        //------------- loose diamond impression GTAG -------------//
        nuxtApp.provide('looseImpressionGtag', (data) => { 
            trigerGtagUtils('looseImpressionGtag', data) 
        })

        //------------- view loose diamond GTAG -------------//
        nuxtApp.provide('looseViewGtag', (data) => {
            trigerGtagUtils('looseViewGtag', data)  
        })

        //-------------  loose diamond to cart GTAG -------------//
        nuxtApp.provide('loosetoCartGtag', (data) => { 
            trigerGtagUtils('loosetoCartGtag', data) 
        })

        //-------------  loose diamond to cart GTAG -------------//
        nuxtApp.provide('appointmentInitiateGtag', () => { 
            trigerGtagUtils('appointmentInitiateGtag', false) 
        })

        //-------------  loose diamond to cart GTAG -------------//
        nuxtApp.provide('appointmentCreationGtag', () => { 
            trigerGtagUtils('appointmentCreationGtag', false) 
        })

        //-------------  loose diamond to cart GTAG -------------//
        nuxtApp.provide('appointmentSucessGtag', () => { 
            trigerGtagUtils('appointmentSucessGtag', false) 
        })

        //-------------  remove from cart GTAG -------------//
        nuxtApp.provide('removefromCartGtag', (item) => { 
            trigerGtagUtils('removefromCartGtag', item) 
        })

        //-------------  checkout address GTAG -------------//
        nuxtApp.provide('addressGtag', (data) => { 
            trigerGtagUtils('addressGtag', data)
        })

        nuxtApp.provide('submitAddressGtag', (data) => { 
            trigerGtagUtils('submitAddressGtag', data)
        })

        //-------------  checkout payment GTAG -------------//
        nuxtApp.provide('paymentGtag', (data) => { 
            trigerGtagUtils('paymentGtag', data)
        })

        //-------------  checkout payment type GTAG -------------//
        nuxtApp.provide('paymentTypeGtag', (pay_type) => { 
            trigerGtagUtils('paymentTypeGtag', pay_type)
        })

        //-------------  checkout payment success GTAG -------------//
        nuxtApp.provide('paymentSuccessGtag', (data, trans_id) => { 
            trigerGtagUtils('paymentSuccessGtag', {'trans_id': trans_id, ...data})
        })

        nuxtApp.provide('jewelryImpressionGtag', (data) => { 
            if(! (data && data.length) ){

                return
            }
            trigerGtagUtils('jewelryImpressionGtag', data)
        })

        nuxtApp.provide('selectJewelryGtag', (data) => { 
            trigerGtagUtils('selectJewelryGtag', data)
        })

        nuxtApp.provide('jewelryDetailGtag', (data) => { 
            trigerGtagUtils('jewelryDetailGtag', data)
        })

        nuxtApp.provide('jewelryToCartGtag', (data) => { 
            trigerGtagUtils('jewelryToCartGtag', data)
        })

        nuxtApp.provide('presetDetailGtag', (data) => { 
            trigerGtagUtils('presetDetailGtag', data)
        })

        nuxtApp.provide('presetToCartGtag', (data) => { 
            trigerGtagUtils('presetToCartGtag', data)
        })

        nuxtApp.provide('bandDetailGtag', (data) => { 
            trigerGtagUtils('bandDetailGtag', data)
        })

        nuxtApp.provide('bandToCartGtag', (data) => { 
            trigerGtagUtils('bandToCartGtag', data)
        })
        //-------------  cart page GTAG -------------//
        nuxtApp.provide('viewCartGtag', (data) => { 
            trigerGtagUtils('viewCartGtag', data)
        })
    };
  
    setAnalytics().catch(console.error);
  
  });
  