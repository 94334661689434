export default {
    setCartData(state, posts) {
        state.cartData = posts;
    },
    setAddressData(state, posts) {
        state.addressData = posts;
    },
    updateCountryCode(state, code) {

        state.addressData.shipping_country_short = code
        state.addressData.billing_country_short = code
    },
    setCartTotal(state, cartData) {
        let cart_total = {

            subtotal: cartData.subtotal_label,
            shipping: cartData.shipping,
            discount_label: cartData.discount_label,
            discount: cartData.discount,

            coupon_label: cartData.coupon?.coupon_discount_label ? cartData.coupon?.coupon_discount_label : 0,
            coupon_discount: cartData.coupon?.coupon_discount ? cartData.coupon?.coupon_discount : 0,

            tax_percent_label: cartData.tax_percent_label,
            tax_label: cartData.tax_label,

            partially_paid: cartData.partially_paid,
            
            couponData: cartData.coupon,

            total: cartData.total_label,

            total_saving: cartData.total_saving_label,

            subtotal_saving: cartData.subtotal_saving,

            coupon_code:  cartData.coupon.coupon,
            
            eta: cartData.eta,

            split: cartData.split,
            is_duplicate: cartData.is_duplicate,
            availability: cartData.availability,
            availability: cartData.availability,
            partially_paid: cartData.partially_paid,
            has_free_product: cartData.has_free_product,
            free_product: cartData.free_product,

            before_christmas: cartData.before_christmas,
            before_new_year: cartData.before_new_year

            // d_locked: posts.d_locked,
        }
        state.cartTotal = cart_total;
    },

    updateCountry(state, code) {
        
        if(code.type === 'shipping'){
            state.addressData.shipping_country = code.country

            state.addressData.shipping_city = ''
            state.addressData.shipping_state = ''
            state.addressData.shipping_postcode = ''
            state.addressData.shipping_address_two = ''
            state.addressData.shipping_address = ''
        }
        if(code.type === 'billing'){
            state.addressData.billing_country = code.country

            state.addressData.billing_city = ''
            state.addressData.billing_state = ''
            state.addressData.billing_address_two = ''
            state.addressData.billing_postcode = ''
            state.addressData.billing_address = ''
        }
       
        // state.addressData.billing_country = code
    },

    setUserLocation(state, data) {
        state.userLocation = data;
        if (state.addressData.shipping_country === '') {

            state.addressData.shipping_country = data.countryName
            state.addressData.billing_country = data.countryName
            state.addressData.shipping_country_short = data.countryCode
            state.addressData.billing_country_short = data.countryCode
        }


    },
    setGuestData(state, posts) {
        state.guestData.first_name = posts.first_name;
        state.guestData.last_name = posts.last_name;
        state.guestData.email = posts.email;
    },
    setErrorData(state, posts) {
        state.errorData = posts;
    },
    clearErrorByKey(state, key) {
        state.errorData[key] = '';
    },
    
    setAddressId(state, address_id) {
        state.address_id = address_id;
    },
    resetAddressData(state){
        
        //reset guest data
        state.guestData.first_name = ''
        state.guestData.last_name = ''
        state.guestData.email = ''
        state.guestData.password = ''
        state.guestData.confirm_password = ''
        state.guestData.is_guest = 1

        //reset shipping data
        state.addressData.billing_address =  ''
        state.addressData.billing_address_two =  ''
        state.addressData.billing_city =  ''
        state.addressData.billing_country =  ''
        state.addressData.billing_first_name =  ''
        state.addressData.billing_last_name =  ''
        state.addressData.billing_phone =  ''
        state.addressData.billing_postcode =  ''
        state.addressData.billing_same =  1
        state.addressData.billing_state =  ''
        state.addressData.order_note =  null
        state.addressData.shipping_address =  ''
        state.addressData.shipping_address_two =  ''
        state.addressData.shipping_city =  ''
        state.addressData.shipping_country =  ''
        state.addressData.shipping_first_name =  ''
        state.addressData.shipping_last_name =  ''
        state.addressData.shipping_phone =  ''
        state.addressData.shipping_postcode =  ''
        state.addressData.shipping_state =  ''
    }
}