<template>
    <div class="prod_box">
        <div class="row align-items-center">
            <div class="col-3 text-center click" @click="presetNavigate(item.setting.full_slug)">
                <img class="radious_four" height="140" width="140" :src="item.setting.image" alt="pre-designed ring">
            </div>
            <div class="col-9">
                <div class="prod_title">
                    <div class="appr_price pe-3">
                        <div class="product_name" @click="presetNavigate(item.setting.full_slug)">
                            <h4 class="mb-2">{{ item.diamond.weight }} Ct {{item.diamond.shape}} Pre-Designed {{ item.setting.info }} {{ item.setting.product_name }}</h4>
                        </div>
                        <div class="appraisal_title" v-if="item.appraisal === 1">
                            <div class="checkbox_custom ms-0">
                                <span :class="{ 'checked': item.appraisal === 1 }"></span>
                                <h4 class="mb-0">Appraisal Certificate ({{ $formatPrice(item.appraisalPrice)
                                    }})</h4>
                            </div>
                        </div>
                        <div class="price_product">
                            <h4 class="mb-0 price_tag">
                                {{ $formatPrice(item.setting.price_label) }}
                            </h4>
                            <div @click="removeFromCart(item)" class="remove_item">
                                    <img width="20" height="24" class="default-icon" src="@/assets/payment_banner/quick_delete.svg" alt="delete_icon">
                                    <img width="20" height="24" class="hover-icon" src="@/assets/payment_banner/quick_delete_red.svg" alt="delete_icon_red">
                                </div>
                            <div class="avail_status mt-2" v-if="item.setting.availability  === 0">
                            <p class="mb-0">This item is no longer available. Please remove it before submitting the order. </p>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
const localePath = useLocalePath()
const { item } = defineProps(['item']);
const emits = defineEmits(['removeFromCart', 'removeAppraisal', 'addAppraisal', 'update:cartVisible', 'closeCart'])
const router = useRouter();
const route = useRoute();

const removeFromCart = (item) => {
    emits('removeFromCart', item)
}

const presetNavigate = (slug) => {
    const path = localePath(slug);
    if(route.name.includes('preset-detail')){
        window.location.href = path;
    }else{
        router.push(path)
    }

    // router.push({path:path, query:{'RefreshKey':item?.id}});
    // router.push(path).then( () => refreshNuxtData() ).catch( (e)=> console.log('error',e ));
};

</script>

<style scoped>
.product_name p {
    text-transform: capitalize;
}

.product_name h4 {
    font-size: 14px;
    font-weight: 400;
    color: #353535;
    cursor: pointer;
    text-transform: capitalize;
}
.price_product{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.price_tag {
    font-size: 14px;
    font-weight: 700;
    color: #353535;
    line-height: 1.7;
}

.remove_item,
.click {
    cursor: pointer;
}

.remove_item {
  display: inline-block;
}

.default-icon {
  display: block;
}

.hover-icon {
  display: none;
}

.remove_item:hover .default-icon {
  display: none;
}

.remove_item:hover .hover-icon {
  display: block;
}
.prod_box {
    /* padding: 12px 0; */
}

.appr_price_right {
    font-size: 16px;
    font-weight: 700;
}

.remove_item {
    cursor: pointer;
}

.edit {
    text-decoration: underline;
}

.sizes_box input.invalid {
    border: 1px solid #ff0000;
}

.edit_remove {
    color: #6a6161;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
}

.avail_status p {
    font-size: 14px;
    color: rgb(255, 0, 0);
    font-weight: 600;
}

.avail_status p span {
    text-decoration: underline;
    cursor: pointer;
}

.appraisal_title {
    margin: 8px 0;
    color: #606060;
}

.appraisal_title h4 {
    font-size: 14px;
}

.row.sizes_box {
    margin-top: 2%;
}

.sizes_box label {
    font-size: 14px;
    font-weight: 600;
    display: block;
    color: #363030;
}

.sizes_box input {
    height: 45px;
    font-weight: 500;
    width: 100%;
    padding: 0 10px;
    outline: none;
}

.offer {
    color: #668b84;
    font-size: 16px;
    font-style: italic;
    margin: 8px 0 0 0;
}

@media(max-width: 992px) {
    .appr_price_left {
        width: 70%;
    }

    .appr_price_right {
        width: 30%;
    }
}

@media(max-width: 767px) {
    .col-8 {
        padding-left: 0;
    }

    .edit_remove {
        color: #6a6161;
        font-size: 14px;
        font-weight: 500;
    }

    .mob_ctas {
        color: var(--green);
        font-size: 14px;
        font-weight: 600;
    }

    .mob_ctas .edit {
        margin-bottom: 5px;
    }

    .offer {
        font-size: 14px;
    }
}
</style>
