
export default async function setCsrfToken(  ) {

	const COOKIE_OPTIONS = { readonly: true };

	const app = useNuxtApp()
    
    const config = app.$config.public.sanctum
	

	async function initCsrfCookie(config) {
		try {
			await $fetch(config.endpoints.csrf, {
			  baseURL: config.baseUrl,
			  credentials: "include"
			});
			
		} catch (error) {
			console.error("Error while fetching CSRF token:", error);
		}
	}

	let csrfToken = useCookie(config.csrf.cookie, COOKIE_OPTIONS);
	if (!csrfToken.value) {
		await initCsrfCookie(config);
		csrfToken = useCookie(config.csrf.cookie, COOKIE_OPTIONS);
	}
}