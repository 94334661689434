<template>
    <div class="prod_box" v-if="item">
        <div class="align-items-center">
            <div class="row align-items-center">
                <div class="col-3 text-center">
                    <div @click="editCyo(item)" class="clic">
                        <img class="radious_four" height="140" width="140" :src="item.setting.image"
                            alt="Create Your Own">
                    </div>
                </div>
                <div class="col-9">
                    <div class="prod_title">
                        <div class="appr_price pe-3">
                            <div class="product_name">
                                <div @click="editCyo(item)" class="">
                                    <h4 class="mb-1" v-if="item.type === 'cyoe'">{{ cyoeweight }} Ct. {{
                                        item?.setting?.product_name }} in {{ item?.setting.info }}</h4>
                                    <h4 class="mb-1" v-else> {{ item.diamond.weight }} Ct {{ item.diamond.shape }} {{ item?.setting?.product_name }} in
                                        {{ item?.setting.info }}</h4>
                                </div>
                            </div>
                            <div class="appraisal_title" v-if="item.appraisal === 1">
                                <div class="checkbox_custom ms-0">
                                    <span :class="{ 'checked': item.appraisal === 1 }"></span>
                                    <h4 class="mb-0">Appraisal Certificate ({{ $formatPrice(item.appraisalPrice)
                                        }})</h4>
                                </div>
                            </div>
                            <div class="price_product">
                                <h4 class="mb-0 price_tag">
                                    {{ $formatPrice(totalPrice) }}
                                </h4>
                                <div @click="removeFromCart(item)" class="remove_item">
                                    <img width="20" height="24" class="default-icon" src="@/assets/payment_banner/quick_delete.svg" alt="delete_icon">
                                    <img width="20" height="24" class="hover-icon" src="@/assets/payment_banner/quick_delete_red.svg" alt="delete_icon_red">
                                </div>

                            </div>
                        </div>
                        <div class="avail_status mt-1" v-if="item.setting.availability === 0">
                            <p class="mb-0">This item is no longer available. Please remove it before submitting the
                                order. </p>
                        </div>
                        <div class="avail_status mt-1"
                            v-if="item.diamond.is_duplicate === 1 || item.diamond.availability === 0">
                            <p class="mb-0">Please choose a new diamond before submitting your order.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="item.wedding" class="row align-items-center">
                <div class="col-3 text-center">
                    <img height="90" width="90" :src="item.wedding.image" class="radious_four" alt="Create Your Own">
                </div>
                <div class="col-9">
                    <div class="prod_title my-3">
                        <div class="appr_price d-flex align-items-top justify-content-between">
                            <div class="appr_price_left">
                                <h4 class="mb-1">{{ item.wedding.product_name }}</h4>
                                <p class="mb-0">{{ item.wedding.desc }}</p>
                                <p class="mb-0">{{ item.wedding.info }}</p>
                            </div>
                            <div class="appr_price_right">
                                <h4 class="mb-0">{{ $formatPrice(item.wedding.price_label) }}</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>


<script setup>
import { cyoStore } from '@/stores/cyo/cyo';
const store = cyoStore()
const { item } = defineProps(['item']);
const emits = defineEmits(['removeFromCart', 'removeAppraisal', 'addAppraisal', 'update:cartVisible', 'closeCart'])

const editCyo = (data) => {
    store.editCyoFromCart(data);
}

const cyoeweight = computed(() => {
    const ear_item = Number(item.diamond.weight) + Number(item.diamond_two.weight);
    return ear_item;
});


const totalPrice = computed(() => {
    if (item.type === 'cyoe') {
        return item?.setting?.price_label + item?.diamond?.price_label + item?.diamond_two?.price_label;
    } else {
        return item?.setting?.price_label + item?.diamond?.price_label;
    }
});

const removeFromCart = (item) => {
    emits('removeFromCart', item);
}
</script>

<style scoped>
.edit {
    text-decoration: underline;
}
.price_product{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.sizes_box input.invalid {
    border: 1px solid #ff0000;
}
.remove_item,
.clic{
    cursor: pointer;
}

.remove_item {
  display: inline-block;
}

.default-icon {
  display: block;
}

.hover-icon {
  display: none;
}

.remove_item:hover .default-icon {
  display: none;
}

.remove_item:hover .hover-icon {
  display: block;
}

.edit_remove {
    color: #6a6161;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
}

.avail_status p {
    font-size: 12px;
    color: rgb(255, 0, 0);
    font-weight: 600;
}

.appraisal_title {
    margin: 8px 0;
    color: #606060;
}

.appraisal_title h4 {
    font-size: 14px;
}

.avail_status p span {
    text-decoration: underline;
    cursor: pointer;
}

.row.sizes_box {
    margin-top: 2%;
}

.sizes_box label {
    font-size: 14px;
    font-weight: 600;
    display: block;
    color: #363030;
}

.sizes_box input {
    font-size: 16px;
    height: 45px;
    font-weight: 500;
    width: 100%;
    padding: 0 10px;
    outline: none;
}

.offer {
    color: #668b84;
    font-size: 16px;
    font-style: italic;
    margin: 8px 0 0 0;
}

.appraisal_title h4 {
    font-size: 14px;
}

.price_tag {
    font-size: 16px;
    font-weight: 700;
    color: #353535;
    line-height: 1.7;
}

.product_name h4 {
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    text-transform: capitalize;
    color: #353535;
}

@media(max-width: 992px) {
    .appr_price_left {
        width: 70%;
    }

    .appr_price_right {
        width: 30%;
    }
}

@media(max-width: 767px) {
    .col-8 {
        padding-left: 0;
    }

    .edit_remove {
        color: #6a6161;
        font-size: 14px;
        font-weight: 500;
    }

    .mob_ctas {
        color: var(--green);
        font-size: 14px;
        font-weight: 600;
    }

    .mob_ctas .edit {
        margin-bottom: 5px;
    }

    .offer {
        font-size: 14px;
    }

    
    .price_tag {
        font-size: 14px;
    }
}
</style>
