<template>
    <div class="header_block">
        <HeaderTopBar />
        <div class="container">
            <div class="upper_menu_block">
                <HeaderContactCtas @openMenu="openMenu" />
                <HeaderFriendlyLogo />
                <div class="user_info_box">
                    <ul class="ps-0 mb-0">
                        <li>
                            <HeaderGlobalSearch />
                        </li>
                        <client-only>
                            <HeaderAccountPopup />
                        </client-only>
                        <!-- <HeaderAccountPopup /> -->
                        <li class="cart_block">
                            <div @click="openQuickCart">
                                <SvgHeaderSvg :type="'cart'" />
                                <div class="count_cart">{{ cartCount }}</div>
                            </div>
                        </li>
                    </ul>
                    <div class="cart_block for_mobile">
                        <div @click="openQuickCart">
                            <SvgHeaderSvg :type="'cart'" />
                            <div class="count_cart">{{ cartCount }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="search_box for_mobile" v-if="showSearch">
                <HeaderGlobalSearch />
            </div>
        </div>


        <transition name="overlay_new">

            <div class="quick-cart-overlay-mix" 
                :class="{ 'visible': isCartVisible }" 
                v-if="isCartVisible"
                @click="closeQuickCart">
            </div>
        </transition>

        <transition name="cart-wrapper">

            <div class="quick-cart-wrapper" v-if="isCartVisible">
            <CartQuickCart 
                @update:cartVisible="closeQuickCart" 
            />
            </div>
        </transition>

        <div class="main_menu_block" @click="handleOutsideClick($event)" :class="{ main_menu_block_open: openOverlay }">
            <div class="main_menu_list" ref="menu_ref" :class="{ main_menu_list_mod: openMainMenu }">
                <div class="for_mobile_mod sticky_auth_menu">
                    <HeaderAuthMenu @closeMenu="closeMenu" @hideShowMenu="hideShowMenu" />
                </div>
                <!-- <pre>{{ subMenuState }}</pre> -->
                <ul class="ps-0 mb-0">
                    <li class="menu-hover" @mouseenter="handleMouseOver('engagement')" @mouseleave="hoverFlag = ''">
                        <HeaderEngagementMenu :openOverlay="openOverlay" :hoverFlag="hoverFlag" menuName="engagement" :subMenuState="subMenuState"  :isHideMenu="isHideMenu" @handleSubMenuState="handleSubMenuState" @hideMenuInDesktop="hideMenuInDesktop" @hideShowMenu="hideShowMenu"  />
                    </li>
                    <li class="menu-hover" @mouseenter="handleMouseOver('wedding')" @mouseleave="hoverFlag = ''">
                        <HeaderWeddingMenu :openOverlay="openOverlay" :hoverFlag="hoverFlag" menuName="wedding" :subMenuState="subMenuState" :isHideMenu="isHideMenu" @handleSubMenuState="handleSubMenuState" @hideMenuInDesktop="hideMenuInDesktop" @hideShowMenu="hideShowMenu"  />
                    </li>
                    <li class="menu-hover" @mouseenter="handleMouseOver('diamond')" @mouseleave="hoverFlag = ''">
                        <HeaderDiamondMenu :openOverlay="openOverlay" :hoverFlag="hoverFlag" menuName="diamond" :subMenuState="subMenuState" :isHideMenu="isHideMenu" @handleSubMenuState="handleSubMenuState" @hideMenuInDesktop="hideMenuInDesktop" @hideShowMenu="hideShowMenu"  />
                    </li>
                    <li class="menu-hover" @mouseenter="handleMouseOver('jewelry')" @mouseleave="hoverFlag = ''">
                        <HeaderJewelryMenu :openOverlay="openOverlay" :hoverFlag="hoverFlag" menuName="jewelry" :subMenuState="subMenuState" :isHideMenu="isHideMenu" @handleSubMenuState="handleSubMenuState" @hideMenuInDesktop="hideMenuInDesktop" @hideShowMenu="hideShowMenu" />
                    </li>
                    <li class="menu-hover" @mouseenter="handleMouseOver('about')" @mouseleave="hoverFlag = ''">
                        <HeaderAboutMenu :openOverlay="openOverlay" :hoverFlag="hoverFlag" menuName="about" :subMenuState="subMenuState" :isHideMenu="isHideMenu" @handleSubMenuState="handleSubMenuState" @hideMenuInDesktop="hideMenuInDesktop" @hideShowMenu="hideShowMenu" />
                    </li>
                    <li class="for_mobile">
                        <div class="main_menu_box" :class="{ main_menu_box_open: openSubMenu === 'currency' }">
                            <div class="menu_back_box for_mobile_mod" @click="openSubMenu = ''">
                                <SvgHeaderSvg :type="'left'" />
                                <span>Back</span>
                            </div>
                        </div>
                    </li>
                    <li class="for_mobile_mod border-0">
                        <div class="accout_box">
                            <ClientOnly>
                                <div class="user_history" v-if="isAuthenticated">
                                    <div>
                                        <router-link @click="hideShowMenu" :to="localePath('/account/detail')">
                                            <SvgHeaderSvg :type="'user'" />
                                            <span>My profile</span>
                                        </router-link>
                                        <router-link @click="hideShowMenu" :to="localePath('/cart')">
                                            <SvgHeaderSvg :type="'cart'" />
                                            <span>my shopping bag</span>
                                        </router-link>
                                        <router-link @click="hideShowMenu" :to="localePath('/account/orders')">
                                            <SvgHeaderSvg :type="'order'" />
                                            <span>my orders</span>
                                        </router-link>
                                    </div>
                                    <p class="mt-3 mb-0" @click="logOut()">
                                        <SvgHeaderSvg :type="'logout'" /><span>logout</span>
                                    </p>
                                </div>
                            </ClientOnly>
                        </div>
                    </li>
                </ul>
                <div class="for_mobile_mod sticky_ankers">
                    <HeaderMobileContactCtas @hideShowMenu="hideShowMenu" />
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { cartStore } from '@/stores/cart/cart';
const store = cartStore()


    const { logout, isAuthenticated  } = useSanctumAuth();
   
    const router = useRouter()
    const route = useRoute()
    
    const localePath = useLocalePath()

    const openSubMenu = ref("")
    const openOverlay = ref(false)
    const openMainMenu = ref(false)
    const currency = ref('USD')
    const menu_ref = ref(null)
    const currencyOptions = ref([
      { value: 'USD', text: 'USD' },
      { value: 'GBP', text: 'GBP' },
      { value: 'EUR', text: 'EUR' }
    ])
    const hoverFlag = ref('')
    const showSearch = ref(true)

    showSearch.value = !route?.name?.includes('search')
    const isHideMenu = ref(false)
    const subMenuState = ref({
        engagement: false,
        wedding:false,
        diamond:false,
        jewelry:false,
        about:false,
    })

    const cartCount = computed(() => {
        return store.fetchCartCount()
    })
    
    const logOut = async () => {
        try {
            hideShowMenu()
            await logout()
            store.setCartCount()
            router.push(localePath('/login'))
        } catch (error) {
            console.log(error,'error')
        }
    }
    const handleSubMenuState = (type)=>{
        const updatedState = subMenuState.value
        nextTick(()=>{
            Object.keys(updatedState).forEach(key=>{
                if(key === type){
                    updatedState[key] = !updatedState[key]  
                }
                
                // else{
                //    updatedState[key] = false
                // }
            })
        })
        subMenuState.value = updatedState
        
    }
const handleOutsideClick = (event) => {
    if (menu_ref.value && !menu_ref.value?.contains(event.target)) {
        openOverlay.value = false
        openMainMenu.value = false
      
    }
}
const closeQuickCart = () => {
    store.setCartVisible(false);
}
    const handleMouseOver = (flag)=>{
        if(flag && !hoverFlag.value ){
            hoverFlag.value = flag
            isHideMenu.value = false
        }
    }
    
    const openMenu = () => {
      openOverlay.value = true
    }
    
    const closeMenu = () => {
      openOverlay.value = false
      openMainMenu.value = false
    }
    
    const changeCurrency = async () => {
      if (currencyOptions.value.map(cv => cv.value).includes(currency.value)) {
        currencyDropdownRef.value.disabled = true
        // await store.dispatch('changeCurrency', currency.value)
        let url = new URL(window.location.href)
        url.searchParams.set('currency', currency.value)
        window.location.href = url.href
    }
}
const hideMenuInDesktop = (currentMenu) => {
    hoverFlag.value = ''
}


    const hideShowMenu = () =>  {
   
        openOverlay.value = false
        openMainMenu.value = false
        isHideMenu.value = true
    
        hoverFlag.value = ''

        if( route && route.name && typeof route.name == 'string' ){
            
            showSearch.value = !route.name.includes('search')
        }

    }
   
    watch(openOverlay, (val) => {
        if(import.meta.client){
            if (val) {
            // document.body.classList.add('filter_open')
            let padding = window.innerWidth - document.documentElement.clientWidth;
    
            document.body.style.paddingRight = `${padding}px`; 
            document.body.style.overflow = 'hidden'; 
          } else {
            // document.body.classList.remove('filter_open')
            document.body.style.paddingRight = `0px`; 
            document.body.style.overflow = 'auto'; 
          }
        }
    })


const isCartVisible = computed(()=>{
    return store.cartVisible
})

const openQuickCart = () => {
    const restrictedRoutes = [localePath('checkout-payment'),localePath('checkout-address'), localePath('cart')]
    // const restrictedRoutes = [
    //     '/checkout/address',
    //     '/in/checkout/address',
    //     '/cart',
    //     '/in/cart',
    //     '/checkout/payment',
    //     '/in/checkout/payment'
    // ];

    if (restrictedRoutes.includes(route.path)) {
        store.setCartVisible(false);
        navigateTo(localePath('/cart'))
    } else {
        store.setCartVisible(true);
    }
};


// watch(() => store.cartVisible, (val) => {
// //   console.log(val, 'val');

//   if (val) {
//     document.body.classList.add('filter_open');
//   } else {
//     document.body.classList.remove('filter_open');
//   }
// });
watch(() => store.cartVisible, (val) => {
    try {
        if (val) {
            document.body.classList.add('filter_open');
        } else {
            document.body.classList.remove('filter_open');
        }
    } catch (error) {
        console.error('Error while watching cartVisible:', error);
    }
});


onMounted(() => {
    if (route.name !== 'cart___in' && route.name !== 'cart___us') {
        store.setCartCount()
    }
})
onBeforeUnmount(() => {
    document.body.classList.remove("filter_open"); // Clean up if the modal was open
});
</script>


<style scoped>
.quick-cart-overlay-mix {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: #0000007a;
    z-index: 9999;
    opacity: 0;
    visibility: hidden;
    /* transition: opacity 0.5s ease, visibility 0.5s ease; */
}

.quick-cart-overlay-mix.visible {
    opacity: 1;
    visibility: visible;
}

.quick-cart-wrapper {
    position: fixed;
    top: 0;
    right: 0;
    width: 400px;
    max-width: 100%;
    /* height: 100%; */
    height: 100vh;
    background: #ffffff;
    z-index: 9999;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    /* overflow: auto; */
    transition: transform 0.5s ease;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 100vh;
}

/* Transition Classes for Overlay */

.cart-wrapper-enter-active,
.cart-wrapper-leave-active {
    transition: transform 0.5s ease;
}

.cart-wrapper-enter-from {
    transform: translateX(100%);
}

.cart-wrapper-enter-to {
    transform: translateX(0);
}

.cart-wrapper-leave-to {
    transform: translateX(100%);
}

@media (max-width : 767px) {

    .quick-cart-wrapper {
        max-width: 350px;
    }

}
</style>
