
import { couponCodeStore } from "../coupon/coupon"

export default {
    async setAddressId(address_id) {
        this.commitMutation('setAddressId', address_id)
    },

    async setCartData(data) {
    //    const {$toast} = useNuxtApp()
        const router = useRouter()
        const localePath = useLocalePath();
        const storeCoupon = couponCodeStore()
        // var coupon = 'vuexContext.rootState.couponModule.couponCode'
        let coupon = storeCoupon.getCouponCode()

        if(data && data.coupon){
            if(coupon != data.coupon){
                coupon = data.coupon
            }
        }
        try{
            const res = await $fetch(useCountryPath('/checkout/address?coupon='+coupon))
       
            if(res.success){
                console.log('res.data.items', res.data.items)
                if(res.data.items.length === 0 || res.data.availability === 0 || res.data.is_duplicate === 1){
                    // $toast('danger', 'Error!', 'Invalid cart items.');
                    return router.push(localePath({name: 'cart'}));  
                }
                
                this.commitMutation('setCartData', res)
                this.commitMutation('setCartTotal', res.data)
                if(res.data.coupon.applied && res.data.coupon.success){
                    storeCoupon.setCouponToStorage(coupon)
                }
                return res
            }
 
        }
        catch(error){
            if(error.status === 400){
                return router.push(localePath({name: 'cart'})); 
            }
        }
    },
    async setAddressData() {
        const router = useRouter()
        const localePath = useLocalePath();

        try{
            const res = await $fetch(useCountryPath('/checkout/address'))
            if(res.data.address != null){
                this.commitMutation('setAddressData', res.data.address)
                this.commitMutation('setGuestData', res.data.address)
                
                return res.data.address
            }
            return
        }
        catch(error){
            if(error.status === 400){
                return router.push(localePath({name: 'cart'})); 
            }
        }
    },

    async setUserLocation() {
        try {
            
            const info = await $fetch(useCountryPath('/get-user-location'));
    
            if( info.data?.ipAddress ){
    
                this.commitMutation('setUserLocation', info.data)
            }
        } catch (error) {
            console.log(error,'error')
        }

    },
    async updateCountryCode(code) {
        this.commitMutation('updateCountryCode', code)
    },

    async updateCountry(code) {
        this.commitMutation('updateCountry', code)
    },


    async addressSubmit(payload) {
        const router = useRouter()
        const localePath = useLocalePath();
        const { login} = useSanctumAuth();
        const user = useSanctumUser();
        const { $submitAddressGtag, $toast } = useNuxtApp()

        const data = payload.data
      
        this.commitMutation('setErrorData', {})
        try {
          
            const res = await $fetch(useCountryPath('/checkout/address/store'), {
                method: 'POST',
                body: data
            })
            if( ! res.success){
                
                throw new Error( 'failed to store address' )
            }
    
            // if(!res.data.is_guest_checkout && !user){

            if(!res.data.is_guest_checkout &&  !user?.value ){
                


                return login({...data, remember:true})
                    .then(( res ) =>{

                        $toast('success', 'Success!', 'Address saved successfully.');

                        setTimeout( () => {
                            this.commitMutation('resetAddressData')
                            // router.push( localePath({ path: '/checkout/payment' } ));
                            window.location.href = localePath({ path: '/checkout/payment' }); 
                        }, 1500)
                        
                        return
                    })
            }
            $submitAddressGtag({phone: '', email: data.email})
            $toast('success', 'Success!', 'Address saved successfully.');
            setTimeout( () => {
                this.commitMutation('resetAddressData')
                // router.push( localePath({ path: '/checkout/payment' } ));
                window.location.href = localePath({ path: '/checkout/payment' }); 
            }, 1500)    
            
            return res

        } catch (err) {
            if( err.status === 422 ){
                this.commitMutation('setErrorData', err.data.errors)
            }else{
                alert("Something went wrong. Please try again after some time or contact admin")
            }
        }
    }, 
    async resetAddressData() {
        this.commitMutation('resetAddressData')
    },

    clearErrorByKey(key){
        this.commitMutation('clearErrorByKey', key)
    }    

}