
import mixpanel from "mixpanel-browser";

export default defineNuxtPlugin((nuxtApp) => {

    // return
    const runtimeConfig = useRuntimeConfig()
    if (!nuxtApp.$country?.is_india && ![false, 'false'].includes(runtimeConfig.public.mixpanel_token)) {
        mixpanel.init(runtimeConfig.public.mixpanel_token, {
            // debug:true,
            track_pageview: true,
            persistence: "localStorage",
        });
    }

    return {
        provide: {
            mixpanel,
        },
    };
});
