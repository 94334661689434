<template>
    <div class="prod_box">
        <div class="row align-items-center">
            <div class="col-3 text-center clic" @click="navigateJewel(item.item.full_slug)">
                <img :src="item.item.image" alt="Product Image" class="img-fluid radious_four" height="280" width="280">
            </div>
            
            <div class="col-9">
                <div class="prod_title">
                    <div class="appr_price pe-3">
                        <div class="product_name" @click="navigateJewel(item.item.full_slug)">
                            <h4 class="mb-2">{{ item.item.product_name }}</h4>
                        </div>
                        <div class="appraisal_title" v-if="item.appraisal === 1">
                            <div class="checkbox_custom ms-0">
                                <span :class="{ 'checked': item.appraisal === 1 }"></span>
                                <h4 class="mb-0">Appraisal Certificate ({{ $formatPrice(item.appraisalPrice)
                                    }})</h4>
                            </div>
                        </div>
                        <div class="price_product">
                            <h4 class="mb-0 price_tag">
                                {{ $formatPrice(item.item.price_label) }}
                            </h4>
                            <div @click="removeFromCart(item)" class="remove_item">
                                    <!-- <SvgHeaderSvg :type="'quick_delete'" />
                                    <SvgHeaderSvg :type="'quick_red_delete'" /> -->
                                    <img width="20" height="24" class="default-icon" src="@/assets/payment_banner/quick_delete.svg" alt="delete_icon">
                                    <img width="20" height="24" class="hover-icon" src="@/assets/payment_banner/quick_delete_red.svg" alt="delete_icon_red">
                                </div>

                        </div>
                    </div>

                    <div class="avail_status mt-3" v-if="item.item.availability === 0">
                        <p class="mb-0">This item is no longer available. Please remove it before submitting the order.
                        </p>
                    </div>
                </div>

            </div>

            <!-- <div class="avail_status mt-3" v-if="item.item.availability === 0">
                <p class="mb-0">This item is no longer available. Please remove it before submitting the order.</p>
            </div> -->
        </div>
        <!-- <p class="offer" v-if="item?.lowestPriceOfferApplied === 1 && !$country.is_india">Congratulations! You’ve scored an extra 25% off on this item! Check your cart summary to view the discount.</p> -->
    </div>
</template>


<script setup>

const localePath = useLocalePath()
const { item } = defineProps(['item']);
const emits = defineEmits(['removeFromCart', 'removeAppraisal', 'addAppraisal', 'update:cartVisible', 'closeCart'])
const router = useRouter();
const route = useRoute();


const removeFromCart = (item) => {
    emits('removeFromCart', item);
};

const navigateJewel = (slug) => {
    const path = localePath(slug);
    if(route.name.includes('shop-detail')){
        window.location.href = path;
    }else{
        router.push(path)
    }
    // router.push({path:path, query:{'RefreshKey':item.id}});
    // router.push(path).then( () => refreshNuxtData() ).catch( (e)=> console.log('error',e ));
};

</script>

<style scoped>
.avail_status p {
    font-size: 12px;
    color: rgb(255, 0, 0);
    font-weight: 600;
}

.avail_status p span {
    text-decoration: underline;
    cursor: pointer;
}

.row.sizes_box {
    margin-top: 2%;
}

.price_tag {
    font-size: 14px;
    font-weight: 700;
    color: #353535;
    line-height: 1.7;
}

.product_name h4 {
    font-size: 14px;
    font-weight: 400;
    color: #353535;
    cursor: pointer;
}

.prod_box {
    /* padding: 12px 0; */
}
.price_product{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.remove_item,
.clic {
    cursor: pointer;
}

.remove_item {
  display: inline-block;
}

.default-icon {
  display: block;
}

.hover-icon {
  display: none;
}

.remove_item:hover .default-icon {
  display: none;
}

.remove_item:hover .hover-icon {
  display: block;
}

.appraisal_title {
    margin: 8px 0;
    color: #606060;
}

.appraisal_title h4 {
    font-size: 14px;
}
.price_product{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.offer {
    color: #668b84;
    font-size: 16px;
    font-style: italic;
    margin: 8px 0 0 0;
}



@media(max-width: 767px) {
    .col-8 {
        padding-left: 0;
    }

    .prod_box {
        /* padding: 12px 0 18px 0; */
    }

    .offer {
        font-size: 14px;
    }
}
</style>
